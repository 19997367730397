import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  Button,
  Chip,
  LinearProgress,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import BlockIcon from "@mui/icons-material/Block";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { Alert } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Document from "./Document";
import Cv from "./Cv";
import { setUser, uploadDocument } from "../../../services/user";
import { LinearProgressWithLabel } from "../../../services/constants";

Uploads.propTypes = {
  id: PropTypes.number,
  user: PropTypes.object.isRequired,
};

function Uploads(props) {
  const { id, user, site } = props;

  const sectionRefs = useRef([]);

  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useState(user.id > 0 ? false : true);
  const [refresh, setRefresh] = useState(false);
  const [accordions, setAccordions] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    console.log("user", user);
  }, []);

  useEffect(() => {
    if (site && user) {
      setRefresh(true);
    }
  }, [site, user, isEditable]);

  useEffect(() => {
    if (refresh) {
      let accords = [
        {
          name: "cv",
          label: "Önéletrajz",
          sublabel: "",
          element: (
            <Cv
              user={user}
              isEditable={isEditable}
              ref={(el) => (sectionRefs.current["cv"] = el)}
              onSubmit={(values) => {
                submit("cv", values);
              }}
            ></Cv>
          ),
        },
        {
          name: "document",
          label: "Fájlok",
          sublabel: "",
          element: (
            <Document
              user={user}
              isEditable={isEditable}
              ref={(el) => (sectionRefs.current["document"] = el)}
              onSubmit={(values) => {
                submit("document", values);
              }}
            ></Document>
          ),
        },
      ];
      setAccordions(accords);
      setRefresh(false);
    }
    return () => {};
  }, [refresh]);

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = () => {
    let sections = {};
    setSaving(true);
    Object.keys(sectionRefs.current).map((section) => {
      sections[section] = sectionRefs.current[section].forms();
    });

    let values = {};
    const merge = (cur, acc) => {
      Object.keys(acc).map((attr) => {
        if (!cur[attr]) {
          cur[attr] = acc[attr];
        } else if (Array.isArray(acc[attr]) && acc[attr].length > 0) {
          acc[attr].map((item, index) => {
            if (!cur[attr][index]) {
              cur[attr][index] = acc[attr][index];
            } else {
              cur[attr][index] = { ...cur[attr][index], ...item };
            }
          });
        } else if (Object.keys(acc[attr]).length > 0) {
          Object.keys(acc[attr]).map((key) => {
            if (!cur[attr][key]) {
              cur[attr][key] = acc[attr][key];
            } else {
              cur[attr][key] = { ...cur[attr][key], ...acc[attr][key] };
            }
          });
        }
      });
      return cur;
    };
    let firstError = null,
      allErrors = {};
    for (const section of Object.keys(sections)) {
      for (const form of sections[section]) {
        if (form && form.validation) {
          const errors = form.validation();
          if (errors && !firstError) {
            firstError = errors[Object.keys(errors)[0]];
          }
          if (Object.keys(errors).length) {
            Object.keys(errors).map((errorField) => {
              const error = errors[errorField];
              if (!allErrors[errorField]) {
                allErrors[errorField] = error;
              } else {
                allErrors[errorField].columns = [
                  ...allErrors[errorField].columns,
                  ...error.columns,
                ];
              }
            });
          }
        }
      }
    }
    setErrors(allErrors);
    if (firstError) {
      console.error(allErrors);
    } else {
      for (const section of Object.keys(sections)) {
        for (const form of sections[section]) {
          if (form && form.submit) {
            const formValue = form.submit();
            // values = { ...values, ...formValue };
            values = merge(values, formValue);
          } else {
            console.log("missing", section);
          }
        }
      }
    }
    console.log("save", values);
    try {
      if (Object.keys(values.userDocuments ?? {}).length) {
        values.userDocuments = Object.keys(values.userDocuments).map(
          (key) => values.userDocuments[key]
        );
      }

      if (values.userDocuments) {
        values.userDocuments.map((item) => {
          if (
            typeof item.originalFilename === "string" &&
            item.originalFilename.match(/^\{.*\}$/)
          ) {
            item.originalFilename = JSON.parse(item.originalFilename);
          }
        });
        uploadDocument(id, { files: values.userDocuments }).then(
          (uploadResponse) => {
            console.log("uploadResponse", uploadResponse);
          }
        );
        delete values.userDocuments;
      }

      // if (
      //   values.userMd?.cvFileName &&
      //   typeof values.userMd.cvFileName === "string" &&
      //   values.userMd.cvFileName.match(/^\{.*\}$/)
      // ) {
      //   values.userMd.cvFileName = JSON.parse(values.userMd.cvFileName);
      // } else if (
      //   values.userHc?.cvFileName &&
      //   typeof values.userHc.cvFileName === "string" &&
      //   values.userHc.cvFileName.match(/^\{.*\}$/)
      // ) {
      //   values.userHc.cvFileName = JSON.parse(values.userHc.cvFileName);
      // }
      setUser(id, values)
        .then((response) => {
          console.log("response", response);
          const time = new Date().getTime();
          setSaving(false);
          navigate(`/users/${site}/${id}?refresh=${time}`);
        })
        .catch((err) => {
          console.error(err);
          setSaving(false);
        });
    } catch (err) {
      console.error(err);
      setSaving(false);
      setErrorMessage(err.message);
    }
  };

  const parseErrors = (errors) => {
    const errorChips = [];
    Object.keys(errors).map((field) => {
      const error = errors[field];
      let label = error.columns[0].headerName ?? field;
      if (error.columns.length > 1) {
        label += ` (${error.columns.length})`;
      }
      errorChips.push(<Chip className={"ms-3"} label={label} color="error" />);
    });
    return errorChips;
  };

  const submit = (section, values) => {
    console.log(`${section} submit`, values);
  };

  const handleCancel = () => {
    console.log("handleCancel");
  };

  const handleCopy = () => {
    console.log("handleCopy");
  };

  const handleDelete = () => {
    console.log("handleDelete");
  };

  return (
    <div>
      <Box>
        {!isEditable && (
          <>
            <Button
              variant={"contained"}
              size={"small"}
              startIcon={<EditIcon />}
              color={"warning"}
              onClick={() => {
                handleEdit();
              }}
              sx={{ mr: 3 }}
            >
              Szerkesztés
            </Button>
          </>
        )}
        {isEditable && user.id > 0 && (
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<EditIcon />}
            color={"error"}
            onClick={() => {
              handleEdit();
            }}
          >
            Szerkesztés visszavonása
          </Button>
        )}
      </Box>
      {accordions.map((accordion, index) => (
        <Accordion key={index} expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {accordion.label}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {accordion.sublabel}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{accordion.element}</AccordionDetails>
        </Accordion>
      ))}
      {saving && <LinearProgressWithLabel value={"Mentés folyamatban..."} />}
      {isEditable && !saving && (
        <>
          <Grid container spacing={0}>
            <Grid>
              <Button
                variant={"contained"}
                startIcon={<SaveIcon />}
                color={Object.keys(errors).length > 0 ? "warning" : "success"}
                onClick={() => {
                  handleSave();
                }}
              >
                {`${
                  Object.keys(errors).length > 0
                    ? "Ellenőrzés és mentés"
                    : "Mentés"
                }`}
              </Button>
            </Grid>
            <Grid>
              <Button
                variant={"contained"}
                startIcon={<BlockIcon />}
                color={"error"}
                onClick={() => {
                  handleCancel();
                }}
              >
                Mégsem
              </Button>
            </Grid>
          </Grid>
          {Object.keys(errors).length > 0 && (
            <Alert severity="error" className={"mt-5"}>
              Hiba az alábbi mezőkben: {parseErrors(errors)}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" className={"mt-5"}>
              Váratlan hiba történt: {errorMessage}
            </Alert>
          )}
        </>
      )}
    </div>
  );
}

export default Uploads;
