import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import { getLanguages } from "../../../services/dictionary";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Paper } from "@mui/material";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";
import { languageLevels } from "../../../services/constants";

const LanguageForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [formItems, setFormItems] = useState({});
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    if (user.userLanguages?.length > 0) {
      const newFormItems = {};
      user.userLanguages.map((item, index) => {
        newFormItems[index] = item;
      });
      setFormItems(newFormItems);
    }
  }, [user]);

  const levelOptions = languageLevels;

  useEffect(() => {
    const newColumns = {};
    Object.keys(formItems).map((index) => {
      const formItem = formItems[index];
      const currentColumns = [
        {
          field: "languageId",
          fieldType: "select",
          fieldModel: "userLanguages",
          fieldModelArrayIndex: index,
          fieldModelId: formItem.id,
          headerName: "Nyelv",
          validation: {
            required: true,
          },
          options: options.languages,
          defaultValue: 0,
          onChange: (event, column) => {
            if (event.target.value) {
              const newFormItems = JSON.parse(JSON.stringify(formItems));
              newFormItems[column.fieldModelArrayIndex].languageId =
                event.target.value;
              setFormItems(newFormItems);
            }
          },
        },
        {
          field: "writtenLevel",
          fieldType: "select",
          fieldModel: "userLanguages",
          fieldModelArrayIndex: index,
          headerName: "Írásban",
          options: levelOptions,
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = JSON.parse(JSON.stringify(formItems));
            newFormItems[column.fieldModelArrayIndex].writtenLevel =
              event.target.value;
            setFormItems(newFormItems);
          },
        },
        {
          field: "spokenLevel",
          fieldType: "select",
          fieldModel: "userLanguages",
          fieldModelArrayIndex: index,
          headerName: "Szóban",
          options: levelOptions,
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = JSON.parse(JSON.stringify(formItems));
            newFormItems[column.fieldModelArrayIndex].spokenLevel =
              event.target.value;
            setFormItems(newFormItems);
          },
        },
      ];
      newColumns[index] = currentColumns;
    });
    setColumns(newColumns);
  }, [formItems]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleAddRow = () => {
    const sorted = Object.keys(formItems).sort(
      (a, b) => parseInt(a) < parseInt(b)
    );
    const newRow =
      sorted.length > 0 ? parseInt(sorted[sorted.length - 1]) + 1 : 0;
    const newItem = {
      languageId: 0,
      writtenLevel: 0,
      spokenLevel: 0,
    };
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    newFormItems[newRow] = newItem;
    setFormItems(newFormItems);
  };

  const handleDeleteRow = (delRow) => {
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    delete newFormItems[delRow];
    setFormItems(newFormItems);
  };

  return (
    <>
      {Object.keys(formItems).map((index) => {
        const currentColumns = columns?.[index] ?? [];
        return (
          <React.Fragment key={index}>
            <Paper className={"bg-paper"}>
              {currentColumns.length > 0 && (
                <Grid container spacing={0} alignItems={"flex-start"}>
                  <Grid xs={10} sm={11} container>
                    <Grid md={4}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[0]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 1] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    <Grid md={4}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[1]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 3] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    <Grid md={4}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[2]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 2] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sm={1}>
                    {isEditable && (
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        className={"mb-6"}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
            </Paper>
          </React.Fragment>
        );
      })}
      {isEditable && (
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow(formItems.length);
          }}
          sx={{ mr: 3 }}
        >
          Nyelvismeret hozzáadása
        </Button>
      )}
    </>
  );
};
const Language = forwardRef(LanguageForwardRef);
Language.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Language;
