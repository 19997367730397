import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { getDrivingLicences } from "../../../services/dictionary";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";

const LicenceFormForwardRef = (props, ref) => {
  const { formik } = props;

  useImperativeHandle(ref, () => ({
    submit() {
      return formik.values;
    },
  }));

  return (
    <>
      <form
        noValidate="novalidate"
        className="form"
        onSubmit={formik.handleSubmit}
      >
        {props.children}
      </form>
    </>
  );
};
const LicenceForm = forwardRef(LicenceFormForwardRef);
LicenceForm.propTypes = {
  formik: PropTypes.object,
};

const DrivingLicenceForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [selectedDrivingLicences, setSelectedDrivingLicences] = useState([]);

  const isDense = true;

  const formik = useFormik({
    initialValues: {
      userDrivingLicences: [],
    },
    onSubmit: (values, formikBag) => {
      console.log("drivingLicence values", values);
    },
  });

  const columns = [
    {
      field: "userDrivingLicences",
      fieldType: "checkbox",
      fieldModel: "userDrivingLicences",
      headerName: "",
    },
  ];

  useEffect(() => {
    const selectedItems = [];
    (user.userDrivingLicences ?? []).map((item) => {
      const found = options.drivingLicences.find(
        (licence) => licence.id === item.drivingLicenceId
      );
      if (found) {
        selectedItems.push(found);
      }
      setSelectedDrivingLicences(selectedItems);
    });
  }, [user, options]);

  useEffect(() => {
    formik.setFieldValue(
      "userDrivingLicences",
      selectedDrivingLicences.map((item) => {
        return {
          drivingLicenceId: item.id,
        };
      })
    );
  }, [selectedDrivingLicences]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <Grid container spacing={0}>
      {columns.length > 0 && (
        <>
          <Grid md={12}>
            {/*
            <Forms
              isDense={true}
              editable={isEditable}
              header={{ skip: true }}
              permissions={["create", "read", "update", "delete"]}
              columns={columns}
              getObj={user}
              ref={(el) => (formRefs.current[0] = el)}
              submitFunc={(values) => handleSubmit(values)}
              drivingLicences={drivingLicences}
            ></Forms>
*/}
            <div className={`card ${isDense ? "border-0" : ""}`}>
              <div className={`card-body ${isDense ? "p-0" : "py-3"}`}>
                <div className="collapse show">
                  {!isEditable &&
                    selectedDrivingLicences.length > 0 &&
                    selectedDrivingLicences.map((item, index) => (
                      <Chip
                        key={index}
                        className={"me-3"}
                        label={item.name}
                        variant="outlined"
                      />
                    ))}
                  {isEditable && (
                    <LicenceForm
                      formik={formik}
                      ref={(el) => (formRefs.current[0] = el)}
                    >
                      <div className={`card-body ${isDense ? "p-0" : "p-9"}`}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="drivingLicences" row>
                            {options.drivingLicences.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                value="end"
                                control={
                                  <Checkbox
                                    name="drivingLicenceId[]"
                                    checked={
                                      !!selectedDrivingLicences.find(
                                        (selected) => selected.id === item.id
                                      )
                                    }
                                    onChange={(event) => {
                                      if (
                                        !event.target.checked &&
                                        selectedDrivingLicences.find(
                                          (selected) => selected.id === item.id
                                        )
                                      ) {
                                        const newSelectedDrivingLicences =
                                          selectedDrivingLicences.filter(
                                            (selected) =>
                                              selected.id !== item.id
                                          );
                                        setSelectedDrivingLicences(
                                          newSelectedDrivingLicences
                                        );
                                      } else if (
                                        event.target.checked &&
                                        !selectedDrivingLicences.find(
                                          (selected) => selected.id === item.id
                                        )
                                      ) {
                                        setSelectedDrivingLicences([
                                          ...selectedDrivingLicences,
                                          options.drivingLicences.find(
                                            (licence) => licence.id === item.id
                                          ),
                                        ]);
                                      }
                                      // return formik.handleChange;
                                    }}
                                    value={item.id}
                                  />
                                }
                                label={item.name}
                                labelPlacement="end"
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                    </LicenceForm>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
const DrivingLicence = forwardRef(DrivingLicenceForwardRef);
DrivingLicence.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default DrivingLicence;
