import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";

import {formError, formFieldError, formSetValue} from "./formUtils";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import QuillLightEditor from "./QuillLightEditor";

const InputForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    customError() {
      // const teError = textEditorError();
      // console.log('textEditor customError', teError);
      return textEditorError();
    },
    returnValue() {
      return value;
    }
  }));
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  const [value, setValue] = useState("");
  const [inited, setInited] = useState(false);
  const [quillValue, setQuillValue] = useState("");
  const [quillText, setQuillText] = useState("");
  const [quillError, setQuillError] = useState("");

  const refQuillEditor = useRef(0);
  const refHidden = useRef(null);

  // console.log("TextEditorInput initialValue", column.field, initialValue);

  const textEditorError = (text) => {
    if (!text) {
      text = quillText;
    }
    if (column.validation?.required) {
      if (!text) {
        return "Kötelező";
      }
    }
    return "";
  };

  // useEffect(() => {
  //   if (
  //     !inited &&
  //     formik.values &&
  //     (Object.keys(formik.values).includes(column.field) ||
  //       (column.fieldModel &&
  //         formik.values[column.fieldModel] &&
  //         Object.keys(formik.values[column.fieldModel]).includes(column.field)))
  //   ) {
  //     if (column.fieldModel) {
  //       setValue(formik.values[column.fieldModel][column.field] ?? "");
  //     } else {
  //       setValue(formik.values[column.field] ?? "");
  //     }
  //     setInited(true);
  //   }
  // }, [formik]);

  useEffect(() => {
    setTimeout(() => {
      if (refQuillEditor.current) {
        const quill = refQuillEditor.current.returnQuill();
        if (quill) {
          handleChange(quill);
        }
      }
    }, 1000);
  }, [refQuillEditor, quillValue]);

  const handleChange = (quill) => {
    const innerHTML = quill.root.innerHTML;
    // console.log('handleChange', innerHTML);
    const text = quill.root.innerText.replace(/[\r\n]/, "");
    setQuillText(text);
    setQuillValue(innerHTML);
    // formSetValue(formik, column, innerHTML);
    // setValue(innerHTML);
    // dirtying();
    // formError(formik, column);
    const error = textEditorError(text);
    setQuillError(error);
    // formik.setFieldError(column.field, error);
  };

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
    }
    setInited(true);
  }, [initialValue]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  const onChange = (event) => {
    // console.log('HIDDEN CHANGED', event.target.value);
    setValue(event.target.value ?? "");
    return formik.handleChange;
  }

  useEffect(() => {
    if (refHidden.current) {
      refHidden.current.addEventListener("onChange", onChange);
    }
  }, [refHidden.current]);

  useEffect(() => {
    // console.log('HIDDEN CHANGED', quillValue);
    setValue(quillValue ?? "");
    // formik.handleChange();
  }, [quillValue]);

  return (
      <FormControl
          sx={{width: "100%"}}
          error={!!quillError}
          component="fieldset"
          variant="standard"
      >
        <div className="row">
          <label
              className={`col-lg-4 col-form-label fs-6 ${
                  column.validation?.required && "required"
              }`}
          >
            <Typography
                className={"fw-bold"}
                sx={{
                  color: `${
                      !!formFieldError(formik, column, true) ? "#d32f2f" : "#3f4254"
                  }`,
                }}
                component={"span"}
            >
              {column.headerName}
            </Typography>
          </label>
          <div className="col-lg-8 fv-row">
            <div className={`form-control form-control-lg form-control-solid`}>
              {inited && (
                  <QuillLightEditor
                      ref={refQuillEditor}
                      column={column}
                      initialValue={initialValue}
                      // value={value}
                      onChange={handleChange}
                  ></QuillLightEditor>
              )}
            </div>
            {quillError && <FormHelperText>{quillError}</FormHelperText>}
          </div>
        </div>
        <input
            type="hidden"
            name={column.field}
            ref={refHidden}
            value={quillValue}
        />
      </FormControl>
  );
};

const TextEditorLightInput = forwardRef(InputForwardRef);

TextEditorLightInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default TextEditorLightInput;
