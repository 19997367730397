import { useEffect, useRef } from "react";

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}

export function inputVisible(isEditable, column, permissions) {
  let editMode = isEditable;
  let visible = true;
  if (editMode && column.editable === false) {
    visible = false;
  } else if (
      editMode &&
      column.field === "id" &&
      column.fieldType !== "hidden"
  ) {
    editMode = false;
  }
  if (
      editMode &&
      !(permissions.includes("update") || permissions.includes("create"))
  ) {
    editMode = false;
  }
  if (!editMode && column.onlyIfEditable === true) {
    visible = false;
  }
  if (
      !editMode &&
      (column.fieldType === "debug" ||
          column.fieldType === "password" ||
          column.field === "id" ||
          column.fieldType === "hidden")
  ) {
    visible = false;
  }
  return {editMode, visible};
}