import React, {useEffect, useRef, useState} from "react";
import {
  getCompanyRequests,
  getCompanyRequest,
  setCompanyRequest,
  deleteCompanyRequest,
  getCompanyRequestCount,
  getOffices,
  getServices,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";
import { getCounties } from "../../services/dictionary";

CompanyRequests.propTypes = {};

function CompanyRequests(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const [filter, setFilter] = useState('');
  const [listFilter, setListFilter] = useState({});

  const permissions = usePermission("dictionary");

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [offices, setOffices] = useState([]);
  useEffect(() => {
    getOffices()
      .then((response) => {
        setOffices(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const [services, setServices] = useState([]);
  useEffect(() => {
    getServices()
      .then((response) => {
        setServices(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const [counties, setCounties] = useState([]);
  useEffect(() => {
    getCounties()
      .then((response) => {
        setCounties(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const types = [
    {
      id: 1,
      value: 1,
      name: "telefon",
    },
    {
      id: 2,
      value: 2,
      name: "dokumentum",
    },
    {
      id: 10,
      value: 10,
      name: "MD",
    },
    {
      id: 20,
      value: 20,
      name: "HC",
    },
  ];

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "type",
      fieldType: "select",
      headerName: "Típus",
      valueGetter: (params) => {
        let v = `ismeretlen (${params.row?.type})`;
        if (types.length) {
          const type = types.find((item) => item.id === params.row?.type);
          if (type) {
            v = type.name;
          }
        }
        return v;
      },
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Név",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "companyName",
      fieldType: "text",
      headerName: "Cégnév",
    },
    {
      field: "email",
      fieldType: "text",
      headerName: "E-mail",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "phone",
      fieldType: "text",
      headerName: "Telefonszám",
    },
    {
      field: "officeId",
      fieldType: "select",
      headerName: "Iroda",
      hide: true,
      valueGetter: (params) => {
        let v = `ismeretlen (${params.row?.officeId})`;
        if (offices.length) {
          const office = offices.find(
            (item) => item.id === params.row?.officeId
          );
          if (office) {
            v = office.name;
          }
        }
        return v;
      },
    },
    {
      field: "serviceId",
      fieldType: "select",
      headerName: "Szolgáltatás",
      valueGetter: (params) => {
        let v = `ismeretlen (${params.row?.serviceId})`;
        if (services.length) {
          const service = services.find(
            (item) => item.id === params.row?.serviceId
          );
          if (service) {
            v = service.name;
          }
        }
        return v;
      },
    },
    {
      field: "comment",
      fieldType: "text",
      headerName: "Megjegyzés",
    },
    {
      field: "documentId",
      fieldType: "",
      headerName: "Dokumentum",
      valueGetter: (params) => {
        let v = "-";
        if (params?.row?.document) {
          v = JSON.stringify(params.row?.document);
        }
        return v;
      },
    },
    {
      field: "userId",
      fieldType: "",
      headerName: "Felhasználó ID",
      hide: true,
      width: 100,
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Árajánlat kérés",
    url: "/address-books/company-requests",
    item: "árajánlat kérés",
  };

  const refTimeout = useRef(null);

  const handleQuickfilter = (value) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
    refTimeout.current = setTimeout(() => {
      setFilter(value);
    }, 100);
  }

  useEffect(() => {
    const where = {};
    if (filter.toString() !== '') {
      const like = `%${filter}%`;
      where.or = [
        {name: {like: like}},
        {companyName: {like: like}},
        {email: {like: like}},
        {comment: {like: like}},
      ];
    }
    setListFilter(where);
  }, [filter]);

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getCompanyRequest}
          setFunc={setCompanyRequest}
          types={types}
          offices={offices}
          services={services}
          counties={counties}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getCompanyRequests}
          getCountFunc={getCompanyRequestCount}
          deleteFunc={deleteCompanyRequest}
          quickfilterFunc={handleQuickfilter}
          getParams={listFilter}
          columns={columns}
          types={types}
          offices={offices}
          services={services}
          counties={counties}
        ></Lists>
      )}
    </>
  );
}

export default CompanyRequests;
