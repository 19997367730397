import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { getAdminCitiesGroup } from "../../../services/dictionary";
import Grid from "@mui/material/Unstable_Grid2";

const FilterCityForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      const ids = [];
      values.map((item) => {
        item.id.split(",").map((id) => {
          if (!ids.includes(id)) {
            ids.push(id);
          }
        });
      });
      if (params.site === "md") {
        filter.userMd = { cityId: { inq: ids } };
      } else if (params.site === "hc") {
        filter.userHc = { cityId: { inq: ids } };
      }
      return filter;
    },
  }));

  const [values, setValues] = useState([]);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  let optionTimer;

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <FormControl sx={{ minWidth: 200, width: "100%" }}>
          <Autocomplete
            name={params.field}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            multiple={true}
            // freeSolo={true}
            options={options}
            isOptionEqualToValue={(option, value) => {
              return option.id === value;
            }}
            getOptionLabel={(option) => option.name ?? ""}
            filterOptions={(x) => {
              return x;
            }}
            onInputChange={(event, value) => {
              clearTimeout(optionTimer);
              optionTimer = setTimeout(() => {
                if (value) {
                  getAdminCitiesGroup(value)
                    .then((response) => {
                      setOptions(response.data);
                    })
                    .catch((err) => console.error(err));
                } else {
                  setOptions([]);
                }
              }, 200);
            }}
            onChange={(event, value) => {
              setValues(value ?? []);
            }}
            value={values}
            renderInput={(params) => (
              <TextField {...params} placeholder={"Több is választható"} />
            )}
          ></Autocomplete>
        </FormControl>
      </Grid>
    </Grid>
  );
};
const FilterCity = forwardRef(FilterCityForwardRef);

FilterCity.propTypes = {
  params: PropTypes.object,
};

export default FilterCity;
