import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Lists from "../../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import {usePermission, useRoles} from "../../../services/auth";
import moment from "moment";
import {userForMonitoring, userWithComment} from "../../../services/user";
import {
  applicationStatuses,
  getPositionApplicants,
  setPositionUserStatus,
} from "../../../services/position";
import Box from "@mui/material/Box";
import {
  IconButton,
  Paper,
  Popover,
  Popper,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Alert,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ButtonGroup,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import UserPopper from "./UserPopper";
import CommentIcon from "@mui/icons-material/Comment";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import FastForwardIcon from "@mui/icons-material/FastForward";
import { grey, red } from "@mui/material/colors";
import { darken, lighten } from "@mui/material/styles";
import { useTraceUpdate } from "../../../services/utils";
import ApplicationMassButtons from "./ApplicationMassButtons";
import slugify from "slugify";
import { getQualifications } from "../../../services/dictionary";
import ApplicantsExport from "./ApplicantsExport";
import XLSX from "xlsx";
import { saveAs } from 'file-saver';

Applicants.propTypes = {};

function Applicants(props) {
  const { id, site, position, attr } = props;

  let permissions = usePermission("position", id);
  const roles = useRoles();
  const isMonitoring = roles.includes('hca_c');

  if (attr?.nobuttons && permissions.includes("applicantswrite")) {
    permissions = permissions.filter((item) => item !== "applicantswrite");
  }

  const [qualifications, setQualifications] = useState([]);
  const [needButtons, setNeedButtons] = useState(false);
  const [columns, setColumns] = useState([]);
  const [applicants, setApplicants] = useState(null);
  const [list, setList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [listFilter, setListFilter] = useState((permissions.includes('applicants') && !permissions.includes('read')) ? ["20"] : ["10"]);
  const [allSelected, setAllSelected] = useState(false);
  const [listHeight, setListHeight] = useState(600);

  const [exportListFilterSave, setExportListFilterSave] = useState(false);

  const refSelectedToChange = useRef([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const refList = useRef(null);

  let buttons = [
    {
      value: [10],
      name: "Jelentkező",
    },
    {
      value: [20],
      name: "Potenciális",
    },
    {
      value: [25],
      name: "Továbbított",
    },
    {
      value: [30],
      name: "Interjú",
    },
    {
      value: [40],
      name: "Bemutatott",
    },
    {
      value: [50],
      name: "Kiválasztott",
    },
    {
      value: [60, 65, 70],
      name: "Állományban",
    },
    {
      value: [90],
      name: "Parkolópályán",
    },
    {
      value: [80],
      name: "Visszautasított",
    },
    { value: [], name: "" },
  ];
  if (permissions.includes('applicants') && !permissions.includes('read')) {
    buttons = [
      {
        value: [20],
        name: "Potenciális",
      },
      {
        value: [25],
        name: "Továbbított",
      },
      {
        value: [30],
        name: "Interjú",
      },
      {
        value: [40],
        name: "Bemutatott",
      },
      {
        value: [50],
        name: "Kiválasztott",
      },
      {
        value: [60, 65, 70],
        name: "Állományban",
      },
      { value: [], name: "" },
    ];
  }

  const handleListFilter = (event, newFilter) => {
    console.log('handleListFilter', event, newFilter);
    if (newFilter === "") {
      setListFilter([]);
    } else {
      setListFilter([newFilter]);
    }
  };

  useEffect(() => {
    getQualifications()
      .then((response) => {
        setQualifications(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // console.log('listFilter', listFilter);
    if (applicants !== null) {
      if (listFilter.length) {
        let filterStatuses = [];
        listFilter.map((item) => {
          filterStatuses = [...filterStatuses, ...item.split('+').map(status => parseInt(status))];
        });
        setList(
          applicants.filter((applicant) =>
              filterStatuses.includes(applicant.status)
          )
        );
      } else if (permissions.includes('applicants') && !permissions.includes('read')) {
        const filterStatuses = buttons.map(item => item.value).flat();
        setList(
            applicants.filter((applicant) =>
                filterStatuses.includes(applicant.status)
            )
        );
      } else {
        setList(applicants);
      }
    }
  }, [applicants, listFilter]);

  useEffect(() => {
    if (refList.current) {
      refList.current.setRows(list);
    }
  }, [list]);

  useEffect(() => {
    if (position.id) {
      getPositionApplicants(position.id)
        .then((response) => {
          setApplicants(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [position]);

  useEffect(() => {
    if (applicants !== null && permissions.includes("applicantswrite")) {
      setNeedButtons(true);
    }
  }, [applicants, permissions]);

  const refPopper = useRef(null);

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const applicationTypes = [
    {
      id: 1,
      name: "webes jelentkezés",
    },
    {
      id: 2,
      name: "admin kiválasztás",
    },
  ];

  const applicationStatusesList = applicationStatuses;

  const handleStatusChange = (id, btn) => {
    refPopper.current.elementPause();
    const ids = Array.isArray(id) ? id : [id];
    const results = {};
    let errorMsg = [];
    ids.map((item) => {
      results[item] = null;
    });
    const finishedThen = () => {
      let isFinished = true;
      ids.map((item) => {
        if (results[item] === null) {
          isFinished = false;
        }
      });
      if (isFinished) {
        if (!errorMsg.length) {
          setSnackbarColor("success");
          setSnackbarMessage("Sikeresen megváltoztattad a státuszát!");
        } else {
          setSnackbarColor("error");
          setSnackbarMessage(errorMsg.join(" "));
        }
        setSnackbarOpen(true);

        // const newListFilter = JSON.parse(JSON.stringify(listFilter));
        const newApplicants = JSON.parse(JSON.stringify(applicants));
        ids.map((applicationId) => {
          const foundIndex = newApplicants.findIndex(
            (item) => applicationId === item.id
          );
          if (foundIndex !== -1 && results[applicationId] !== true) {
            newApplicants[foundIndex] = results[applicationId];
            let currentStatus = results[applicationId].status.toString();
            if (["60","65","70"].includes(currentStatus)) {
              currentStatus = "60+65+70";
            }
            // if (
            //   !newListFilter.includes(currentStatus)
            // ) {
            //   newListFilter.push(currentStatus);
            // }
          }
        });
        // if (newListFilter.length !== listFilter.length) {
        //   setListFilter(newListFilter);
        // }
        const sortApplicants = (a, b) => {
          if (a.updateTime < b.updateTime) {
            return 1;
          } else if (a.updateTime > b.updateTime) {
            return -1;
          }
          return 0;
        };
        const sortedApplicants = newApplicants.sort(sortApplicants);
        setApplicants(sortedApplicants);
      }
    };
    ids.map((item) => {
      setPositionUserStatus(item, btn).then((response) => {
        if (response.data.success) {
          if (response.data.data.id && response.data.data.status) {
            const found = Object.assign(
              {},
              applicants.find(
                (applicant) => applicant.id === response.data.data.id
              )
            );
            if (found) {
              found.updateTime = response.data.data.updateTime;
              found.status = response.data.data.status;
              results[found.id] = found;
            } else {
              results[response.data.data.id] = true;
            }
          }
        } else {
          errorMsg = [...errorMsg, ...response.data.message];
        }
        finishedThen();
      });
    });
  };

  const handleMassChange = (btn) => {
    refPopper.current.elementPause();
    // console.log("MassChange", selectedToChange, btn);
    handleStatusChange(refSelectedToChange.current, btn);
  };

  const handleSelectionChanged = (currentList) => {
    const statuses = [];
    refSelectedToChange.current
      .map((id) => currentList.find((item) => item.id === id))
      .map((item) => {
        // if (!item?.status) {
          // console.log("item", item);
        // }
        if (!statuses.includes(item.status)) {
          statuses.push(item.status);
        }
      });
    setSelectedStatuses(statuses);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleSelectToChange = (id, currentList) => {
    refPopper.current.elementPause();
    if (refSelectedToChange.current.includes(id)) {
      refSelectedToChange.current = refSelectedToChange.current.filter(
        (item) => item !== id
      );
    } else {
      refSelectedToChange.current = [...refSelectedToChange.current, id];
    }
    handleSelectionChanged(currentList);
  };

  useEffect(() => {
    setColumns([
      {
        field: "id",
        fieldType: "number",
        hide: true,
      },
      {
        field: "name",
        fieldType: "",
        headerName: "Név",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          return `${user?.lastName} ${user?.firstName}`;
        },
        renderCell: (params) => {
          const user = params.row.user ?? null;
          if (needButtons) {
            return (
              <>
                <FormGroup id={`row-${params.row.id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={refSelectedToChange.current.includes(
                          params.row.id
                        )}
                        onChange={() => {
                          handleSelectToChange(params.row.id, list);
                        }}
                        name="user-selected"
                      />
                    }
                    label={isMonitoring ? userForMonitoring(user, true) : userWithComment(user, true)}
                  />
                </FormGroup>
              </>
            );
          }
          return (
            <div id={`row-${params.row.id}`}>{isMonitoring ? userForMonitoring(user, true) : userWithComment(user, needButtons)}</div>
          );
        },
      },
      {
        field: "city",
        fieldType: "",
        hide: true,
        headerName: "Település",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          return `${
            user?.userMd?.city?.name ?? user?.userHc?.city?.name ?? ""
          }`;
        },
      },
      {
        field: "birthDate",
        fieldType: "",
        hide: true,
        headerName: "Születési dátum",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          if (user?.birthDate) {
            return `${moment(user?.birthDate).format("YYYY-MM-DD") ?? ""}`;
          }
          return "";
        },
      },
      {
        field: "qualification",
        fieldType: "",
        hide: true,
        headerName: "Végzettség",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          if (
            user.highestQualificationId &&
            qualifications.find(
              (item) => item.id === user.highestQualificationId
            )
          ) {
            return `${
              qualifications.find(
                (item) => item.id === user.highestQualificationId
              ).name
            }`;
          } else if (user.userQualifications?.length) {
            let highest = null;
            for (const qualification of user.userQualifications) {
              if (
                qualification.qualificationId &&
                (qualifications.find(
                  (item) => item.id === qualification.qualificationId
                )?.level ?? 0) > (highest?.level ?? -1)
              ) {
                highest = qualifications.find(
                  (item) => item.id === qualification.qualificationId
                );
              }
            }
            if (highest) {
              return highest.name;
            }
          }
          return "";
        },
      },
      {
        field: "phone",
        fieldType: "",
        hide: true,
        headerName: "Mobilszám",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          if (user?.userMd?.phone_1Region && user?.userMd?.phone_1Number) {
            return `${user?.userMd?.phone_1Country ?? "36"} ${
              user.userMd.phone_1Region
            } ${user.userMd.phone_1Number}`;
          } else if (
            user?.userHc?.phone_1Region &&
            user?.userHc?.phone_1Number
          ) {
            return `${user?.userHc?.phone_1Country ?? "36"} ${
              user.userHc.phone_1Region
            } ${user.userHc.phone_1Number}`;
          }
          return "";
        },
      },
      {
        field: "link",
        fieldType: "",
        hide: true,
        headerName: "Link",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          return `${process.env.REACT_APP_ADMIN_URL}/users/${
            user?.type === 10 ? "md" : user?.type === 20 ? "hc" : "non"
          }/${user.id}`;
        },
      },
      {
        field: "ageGender",
        fieldType: "",
        headerName: "Kor",
        valueGetter: (params) => {
          const user = params.row.user ?? null;
          let age = 0;
          if (user?.birthDate) {
            age = parseInt(
              moment.duration(moment().diff(moment(user.birthDate))).asYears()
            );
          }
          let gender = "";
          if (user?.sex) {
            gender = user.sex === 1 ? "F" : user.sex === 2 ? "N" : "";
          }
          let data = `${age ? age : ""} ${gender}`;
          return data;
        },
        renderCell: (params) => {
          const user = params.row.user ?? null;
          let age = 0;
          if (user?.birthDate) {
            age = parseInt(
              moment.duration(moment().diff(moment(user.birthDate))).asYears()
            );
          }
          let gender = "";
          if (user?.sex) {
            gender = user.sex === 1 ? "F" : user.sex === 2 ? "N" : "";
          }
          let data = `${age ? age : ""} ${gender}`;
          return data;
        },
      },
      {
        field: "email",
        fieldType: "",
        headerName: "Email",
        hide: true,
        valueGetter: (params) => {
          return params.row.user?.email ?? "";
        },
      },
      {
        field: "create",
        fieldType: "",
        width: 150,
        headerName: "Jelentkezés dátuma",
        align: "right",
        valueGetter: (params) => {
          const createTime = moment(params.row.createTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let applicationType = "";
          if (params.row.applyType) {
            applicationType = applicationTypes.find(
              (item) => item.id === params.row.applyType
            ).name;
          }
          return `${createTime} (${applicationType})`;
        },
        renderCell: (params) => {
          const createTime = moment(params.row.createTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let applicationType = "";
          if (params.row.applyType) {
            applicationType = applicationTypes.find(
              (item) => item.id === params.row.applyType
            ).name;
          }
          return (
            <Box sx={{ textAlign: "right" }}>
              {createTime}
              <br />
              {applicationType}
            </Box>
          );
        },
      },
      {
        field: "status",
        fieldType: "",
        headerName: "Állapot",
        align: "right",
        valueGetter: (params) => {
          let applicationStatus = "";
          if (params.row.status) {
            applicationStatus = applicationStatusesList.find(
              (item) => item.id === params.row.status
            ).name;
          }
          return applicationStatus;
        },
        renderCell: (params) => {
          let applicationStatus = "";
          if (params.row.status) {
            applicationStatus = applicationStatusesList.find(
              (item) => item.id === params.row.status
            ).name;
          }
          return applicationStatus;
        },
      },
      {
        field: "actions",
        align: "right",
        headerName: "",
        headerAlign: "right",
        hide: !needButtons,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerClassName: "text-dark",
        cellClassName: "text-dark",
        renderCell: (params) => {
          return (
            <>
              {needButtons && (
                <>
                  {params.row.status < 60 && (
                    <Tooltip title="Továbbléptetés a következő státuszba">
                      <IconButton
                        variant={"contained"}
                        aria-label="Továbbléptetés a következő státuszba"
                        size="large"
                        className={"me-3"}
                        onClick={() => {
                          handleStatusChange(params.row.id, "stepForward");
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.row.status == 20 && (
                    <Tooltip title="Továbbléptetés interjúztatás nélkül">
                      <IconButton
                        variant={"contained"}
                        aria-label="Továbbléptetés interjúztatás nélkül"
                        size="large"
                        className={"me-3"}
                        onClick={() => {
                          handleStatusChange(params.row.id, "stepForwardFfwd");
                        }}
                      >
                        <FastForwardIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.row.status < 60 && (
                    <Tooltip title="Parkolópályára tesz">
                      <IconButton
                        variant={"contained"}
                        aria-label="Parkolópályára tesz"
                        size="large"
                        className={"me-3"}
                        onClick={() => {
                          // console.log("pause");
                          handleStatusChange(params.row.id, "pause");
                        }}
                      >
                        <PauseIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.row.status === 90 && (
                    <Tooltip title="Parkolópályáról visszavesz">
                      <IconButton
                        variant={"contained"}
                        aria-label="Parkolópályáról visszavesz"
                        size="large"
                        className={"me-3"}
                        onClick={() => {
                          handleStatusChange(params.row.id, "unpause");
                        }}
                      >
                        <NotStartedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(params.row.status < 60 || params.row.status === 90) && (
                    <Tooltip title="Visszautasít">
                      <IconButton
                        variant={"contained"}
                        aria-label="Visszautasít"
                        size="large"
                        className={"me-3"}
                        onClick={() => {
                          handleStatusChange(params.row.id, "reject");
                        }}
                      >
                        <ThumbDownIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          );
        },
      },
    ]);
  }, [needButtons, list]);

  // ide jon a fejlec deklaralasa
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

  const exportFilename = `${slugify(position.name, {
    lower: true,
  })}-jelentkezok-${moment().format("YYYYMMDD-HHmmss")}`;

  const exportOptions = {
    allColumns: false,
    fileName: exportFilename,
    fields: [
      "name",
      "city",
      "birthDate",
      "qualification",
      "phone",
      "email",
      "create",
      "status",
      "link",
    ],
  };

  const refExportLabel = useRef('');
  const handleChangeListFilter = (newFilter, label) => {
    refExportLabel.current = label;
    setExportListFilterSave(listFilter);
    setListFilter(newFilter.value);
  };

  const exportToExcel = (data, fileName) => {
    const XLSX = require("xlsx");
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${fileName}.xlsx`);
  };

  useEffect(() => {
    if (exportListFilterSave) {
      setExportListFilterSave(false);
      setTimeout(() => {
        let exportApplicants = [];
        if (applicants !== null) {
          if (listFilter.length) {
            let filterStatuses = [];
            listFilter.map((item) => {
              if (item) {
                filterStatuses = [...filterStatuses, ...item.split('+').map(status => parseInt(status))];
              }
            });
            console.log('filterStatuses', filterStatuses);
            if (filterStatuses.length) {
              exportApplicants = applicants.filter((applicant) =>
                  filterStatuses.includes(applicant.status)
              );
            } else {
              exportApplicants = applicants;
            }
          } else if (permissions.includes('applicants') && !permissions.includes('read')) {
            const filterStatuses = buttons.map(item => item.value).flat();
            exportApplicants =
                applicants.filter((applicant) =>
                    filterStatuses.includes(applicant.status)
                );
          } else {
            exportApplicants = applicants;
          }
          const toExport = exportApplicants.map(item => {
            const user = item.user ?? null;
            let age = 0;
            if (user?.birthDate) {
              age = parseInt(
                  moment.duration(moment().diff(moment(user.birthDate))).asYears()
              );
            }
            let gender = "";
            if (user?.sex) {
              gender = user.sex === 1 ? "Férfi" : user.sex === 2 ? "Nő" : "";
            }
            let applicationType = "";
            if (item.applyType) {
              applicationType = applicationTypes.find(
                  (_item) => _item.id === item.applyType
              )?.name ?? '';
            }
            let applicationStatus = "";
            if (item.status) {
              applicationStatus = applicationStatusesList.find(
                  (_item) => _item.id === item.status
              )?.name ?? '';
            }
            let phone = '';
            if (item.user?.userHc) {
              if (item.user.userHc.phone_1Number && item.user.userHc.phone_1Region) {
                phone = `${item.user.userHc.phone_1Country ?? '06'}/${item.user.userHc.phone_1Region}/${item.user.userHc.phone_1Number}`;
              }
            } else if (item.user?.userMd) {
              if (item.user.userMd.phone_1Number && item.user.userMd.phone_1Region) {
                phone = `${item.user.userMd.phone_1Country ?? '06'}/${item.user.userMd.phone_1Region}/${item.user.userMd.phone_1Number}`;
              }
            }
            const row = {
              id: item.user?.id,
              "név": item.user ? `${item.user.lastName} ${item.user.firstName}` : '',
              "email": item.user?.email ?? '',
              "születési dátum": item.user?.birthDate ? moment(item.user?.birthDate).format('YYYY-MM-DD') : '',
              "kora": `${age} év`,
              "neme": gender,
              "település": item.user?.userHc?.city?.name ?? item.user?.userMd?.city?.name ?? '',
              "telefonszám": phone,
              "jelentkezés dátuma": moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
              "jelentkezés módja": applicationType,
              "státusz": applicationStatus,
              "hivatkozás": `${process.env.REACT_APP_ADMIN_URL}/users/${item.user?.type === 10 ? 'md': 'hc'}/${item.user?.id}`,
            }
            return row;
          });
          console.log('toExport', toExport);
          exportToExcel(toExport, `jelentkezok-${position.id}-${slugify(position.name).toLowerCase()}-${slugify(refExportLabel.current).toLowerCase()}-${moment().format('YYYY-MM-DD-HH-mm-ss')}`);
        }
      }, 1000);
    }
  }, [list, exportListFilterSave]);

  const addonHeader = (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid>
        <Typography variant={"body2"}>Export:</Typography>
      </Grid>
      <Grid>
        <ApplicantsExport
          handleChangeListFilter={handleChangeListFilter}
        ></ApplicantsExport>
      </Grid>
    </Grid>
  );

  const header = {
    title: "Jelentkezők",
    url: "",
    item: "",
    nopadding: attr?.nopadding ?? false,
    // exportOptions,
    disableExportToolbar: true,
    styles: ({ theme }) => ({
      "& .row-inactive": {
        backgroundColor: getBackgroundColor(grey[300], theme.palette.mode),
        color: grey[500],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              grey[300],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-pending": {
        backgroundColor: getBackgroundColor(grey[200], theme.palette.mode),
        color: grey[500],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            grey[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            grey[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              grey[200],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-deleted": {
        backgroundColor: getBackgroundColor(red[200], theme.palette.mode),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            red[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            red[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              red[200],
              theme.palette.mode
            ),
          },
        },
      },
    }),
    getRowClassName: (params) => {
      if (!params.row.user.isActive || params.row.user.status <= 20) {
        return "row-inactive";
      } else if (params.row.user.status === 10) {
        return "row-pending";
      } else if (params.row.user.status === 40) {
        return "row-deleted";
      } else {
        return "row-active";
      }
    },
  };

  const filterButtons = buttons.map((btn, index) => {
    if (applicants === null) {
      return <ToggleButton key={index} value={""}></ToggleButton>;
    }
    const count = applicants.filter((item) =>
      btn.value.includes(item.status)
    ).length;
    let btnElem = <ToggleButton key={index} value={""}></ToggleButton>;
    if (btn.value.length > 0) {
      btnElem = (
        <ToggleButton
          key={index}
          value={btn.value.join("+")}
          aria-label={btn.name}
        >
          <Tooltip title={`${btn.name} (${count})`}>
            <span>
              {btn.name}
              <br />({count})
            </span>
          </Tooltip>
        </ToggleButton>
      );
    } else if (!btn.value.length) {
      btnElem = (
        <ToggleButton key={index} value={""} aria-label={btn.name}>
          <CloseIcon />
        </ToggleButton>
      );
    }
    return btnElem;
  });

  useEffect(() => {
    refPopper.current.elementPause();
    if (allSelected) {
      refSelectedToChange.current = list.map((item) => item.id);
    } else {
      refSelectedToChange.current = [];
    }
    handleSelectionChanged(list);
  }, [allSelected, list]);

  useEffect(() => {
    setListHeight(list.length * 52 + 280);
  }, [list]);

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid md={12} lg={12}>
          <ToggleButtonGroup
            value={listFilter}
            onChange={handleListFilter}
            aria-label="lista szűrés"
            size={"small"}
            fullWidth={true}
            exclusive={true}
            sx={{ "& .MuiButtonBase-root": { textTransform: "none" } }}
          >
            {filterButtons.map((elem, index) => elem)}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: `${listHeight}px` }}>
        {needButtons && (
          <Box>
            <Grid container>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allSelected}
                        onChange={() => {
                          setAllSelected(!allSelected);
                        }}
                        name="all-selected"
                      />
                    }
                    label={allSelected ? "Mindet törli" : "Mindet kijelöl"}
                  />
                </FormGroup>
              </Grid>
              <ApplicationMassButtons
                selectedStatuses={selectedStatuses}
                handleMassChange={handleMassChange}
                selectedToChange={refSelectedToChange}
              ></ApplicationMassButtons>
            </Grid>
          </Box>
        )}
        {columns.length > 1 && (
          <Lists
            ref={refList}
            header={header}
            permissions={() => {
              return Promise.resolve({ data: ["read"] });
            }}
            addonHeader={needButtons ? addonHeader : <></>}
            // getFunc={Promise.resolve({ data: list ?? [] })}
            getFunc={list}
            columns={columns}
            applicationTypes={applicationTypes}
            applicationStatuses={applicationStatusesList}
            onRowEnter={(event, rows) => {
              const rowId = Number(event.currentTarget.dataset.id);
              const row = rows.find((el) => el.id === rowId);
              // refPopper.current.elementOn(event.currentTarget, row.user);
              refPopper.current.elementOn(row, row.user);
            }}
            onRowLeave={() => {
              refPopper.current.elementOff();
            }}
          ></Lists>
        )}
      </Box>
      <UserPopper ref={refPopper}></UserPopper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Applicants;
