import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, IconButton, Paper } from "@mui/material";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";
import moment from "moment";

const QualificationForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [formItems, setFormItems] = useState({});
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    if (user.userQualifications?.length > 0) {
      const newFormItems = {};
      user.userQualifications.map((item, index) => {
        newFormItems[index] = item;
      });
      setFormItems(newFormItems);
    }
  }, [user]);

  useEffect(() => {
    const newColumns = {};
    Object.keys(formItems).map((index) => {
      const formItem = formItems[index];
      const currentQualification = options.qualifications.find(
        (item) => item.id === formItem.qualificationId
      );
      const currentFieldsOfStudyCategoryId =
        formItem.fieldsOfStudyCategoryId ?? 0;
      const currentColumns = [
        [
          {
            field: "qualificationId",
            fieldType: "select",
            fieldModel: "userQualifications",
            fieldModelArrayIndex: index,
            fieldModelId: formItem.id,
            headerName: "Végzettség",
            validation: {
              required: true,
            },
            options: options.qualifications,
            defaultValue: 0,
            onChange: (event, column) => {
              if (event.target.value) {
                const newFormItems = JSON.parse(JSON.stringify(formItems));
                newFormItems[column.fieldModelArrayIndex].qualificationId =
                  event.target.value;
                setFormItems(newFormItems);
              }
            },
          },
        ],
      ];
      if (currentQualification?.level > 3) {
        currentColumns[0].push({
          field: "fieldsOfStudyCategoryId",
          fieldType: "select",
          fieldModel: "userQualifications",
          fieldModelArrayIndex: index,
          headerName: "Tanulmányterület",
          options: options.fieldOfStudyCategories,
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = Object.assign({}, formItems);
            newFormItems[column.fieldModelArrayIndex].fieldsOfStudyCategoryId =
              event.target.value;
            setFormItems(newFormItems);
          },
        });
        currentColumns[0].push({
          field: "fieldsOfStudyId",
          fieldType: "select",
          fieldModel: "userQualifications",
          fieldModelArrayIndex: index,
          headerName: "Szakvégzettség",
          options: currentFieldsOfStudyCategoryId
            ? options.fieldOfStudies.filter(
                (item) =>
                  item.fieldsOfStudyCategoryId ===
                  currentFieldsOfStudyCategoryId
              )
            : [],
          defaultValue: 0,
        });
      }

      currentColumns.push({
        field: "school",
        fieldType: "text",
        fieldModel: "userQualifications",
        fieldModelArrayIndex: index,
        headerName: "Oktatási intézmény",
        defaultValue: "",
      });
      currentColumns.push({
        field: "name",
        fieldType: "text",
        fieldModel: "userQualifications",
        fieldModelArrayIndex: index,
        headerName: "Végzettség megnevezése",
        defaultValue: "",
      });
      currentColumns.push({
        field: "skills",
        fieldType: "text",
        fieldModel: "userQualifications",
        fieldModelArrayIndex: index,
        headerName: "Tárgyak, készségek",
        defaultValue: "",
      });
      currentColumns.push({
        field: "startDate",
        fieldType: "date",
        fieldModel: "userQualifications",
        fieldModelArrayIndex: index,
        headerName: "Kezdő dátum",
        defaultValue: "",
        valueGetter: (params) => {
          return params.row ? moment(params.row).format("YYYY-MM-DD") : "";
        },
      });
      currentColumns.push({
        field: "endDate",
        fieldType: "date",
        fieldModel: "userQualifications",
        fieldModelArrayIndex: index,
        headerName: "Záró dátum",
        defaultValue: "",
        valueGetter: (params) => {
          return params.row ? moment(params.row).format("YYYY-MM-DD") : "";
        },
      });
      newColumns[index] = currentColumns;
    });
    setColumns(newColumns);
  }, [formItems]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleAddRow = () => {
    const sorted = Object.keys(formItems).sort(
      (a, b) => parseInt(a) < parseInt(b)
    );
    const newRow =
      sorted.length > 0 ? parseInt(sorted[sorted.length - 1]) + 1 : 0;
    const newItem = {
      qualificationId: 0,
    };
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    newFormItems[newRow] = newItem;
    setFormItems(newFormItems);
  };

  const handleDeleteRow = (delRow) => {
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    delete newFormItems[delRow];
    setFormItems(newFormItems);
  };

  return (
    <>
      {Object.keys(formItems).map((index) => {
        const currentColumns = columns?.[index] ?? [];
        return (
          <React.Fragment key={index}>
            {currentColumns.length > 0 && (
              <Paper className={"bg-paper"}>
                <Grid container spacing={0} alignItems={"flex-start"}>
                  <Grid xs={10} sm={11} container>
                    <Grid xs={12} container>
                      <Grid md={4}>
                        <Forms
                          isDense={true}
                          editable={isEditable}
                          header={{ skip: true }}
                          permissions={["create", "read", "update", "delete"]}
                          columns={[currentColumns[0][0]]}
                          getObj={user}
                          ref={(el) => (formRefs.current[index * 10 + 1] = el)}
                          submitFunc={(values) => handleSubmit(values)}
                        ></Forms>
                      </Grid>
                      {currentColumns[0].length > 1 && (
                        <>
                          <Grid md={4}>
                            <Forms
                              isDense={true}
                              editable={isEditable}
                              header={{ skip: true }}
                              permissions={[
                                "create",
                                "read",
                                "update",
                                "delete",
                              ]}
                              columns={[currentColumns[0][1]]}
                              getObj={user}
                              ref={(el) =>
                                (formRefs.current[index * 10 + 3] = el)
                              }
                              submitFunc={(values) => handleSubmit(values)}
                            ></Forms>
                          </Grid>
                          <Grid md={4}>
                            <Forms
                              isDense={true}
                              editable={isEditable}
                              header={{ skip: true }}
                              permissions={[
                                "create",
                                "read",
                                "update",
                                "delete",
                              ]}
                              columns={[currentColumns[0][2]]}
                              getObj={user}
                              ref={(el) =>
                                (formRefs.current[index * 10 + 2] = el)
                              }
                              submitFunc={(values) => handleSubmit(values)}
                            ></Forms>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid xs={12} container>
                      <Grid md={6}>
                        <Forms
                          isDense={true}
                          editable={isEditable}
                          header={{ skip: true }}
                          permissions={["create", "read", "update", "delete"]}
                          columns={[currentColumns[1]]}
                          getObj={user}
                          ref={(el) => (formRefs.current[index * 10 + 3] = el)}
                          submitFunc={(values) => handleSubmit(values)}
                        ></Forms>
                      </Grid>
                      <Grid md={6}>
                        <Forms
                          isDense={true}
                          editable={isEditable}
                          header={{ skip: true }}
                          permissions={["create", "read", "update", "delete"]}
                          columns={[currentColumns[2]]}
                          getObj={user}
                          ref={(el) => (formRefs.current[index * 10 + 4] = el)}
                          submitFunc={(values) => handleSubmit(values)}
                        ></Forms>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[3]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 5] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    <Grid xs={12} container>
                      <Grid md={6}>
                        <Forms
                          isDense={true}
                          editable={isEditable}
                          header={{ skip: true }}
                          permissions={["create", "read", "update", "delete"]}
                          columns={[currentColumns[4]]}
                          getObj={user}
                          ref={(el) => (formRefs.current[index * 10 + 6] = el)}
                          submitFunc={(values) => handleSubmit(values)}
                        ></Forms>
                      </Grid>
                      <Grid md={6}>
                        <Forms
                          isDense={true}
                          editable={isEditable}
                          header={{ skip: true }}
                          permissions={["create", "read", "update", "delete"]}
                          columns={[currentColumns[5]]}
                          getObj={user}
                          ref={(el) => (formRefs.current[index * 10 + 7] = el)}
                          submitFunc={(values) => handleSubmit(values)}
                        ></Forms>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sm={1}>
                    {isEditable && (
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        className={"mb-6"}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )}
          </React.Fragment>
        );
      })}
      {isEditable && (
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow();
          }}
          sx={{ mr: 3 }}
        >
          Végzettség hozzáadása
        </Button>
      )}
    </>
  );
};
const Qualification = forwardRef(QualificationForwardRef);
Qualification.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Qualification;
