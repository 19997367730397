import React, {useEffect, useRef, useState} from "react";
import Lists from "../../modules/hcmd/components/Lists";
import {usePermission, useRoles} from "../../services/auth";
import {
  getUserDeletionLogCount,
  getUserDeletionLogs, roleLevel,
} from "../../services/user";
import moment from "moment";
import {Alert} from "@mui/material";
import {getEmailSubscriberCount, getEmailSubscribers} from "../../services/address-book";

DeletionLogs.propTypes = {};

function DeletionLogs(props) {
  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const permissions = usePermission("user");
  const roles = useRoles();
  const userRoleLevel = roleLevel(roles);

  const [filter, setFilter] = useState('');
  const [listFilter, setListFilter] = useState({ siteId: "hc" });

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      headerName: "Név",
    },
    {
      field: "email",
      headerName: "E-mail",
    },
    {
      field: "type",
      headerName: "Oldal",
      valueGetter: (params) => {
        if (params.row.type === 10) {
          return "MD (10)";
        } else if (params.row.type === 20) {
          return "HC (20)";
        } else {
          return `egyéb (${params.row.type})`;
        }
      },
    },
    {
      field: "createTime",
      headerName: "Törlés ideje",
      valueGetter: (params) => {
        return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "actions",
      hide: true,
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Törlési napló",
    url: "/users/deletion-logs",
    item: "törlési napló",
  };

  const refTimeout = useRef(null);

  const handleQuickfilter = (value) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
    refTimeout.current = setTimeout(() => {
      setFilter(value);
    }, 100);
  }

  useEffect(() => {
    const where = {};
    if (filter.toString() !== '') {
      const like = `%${filter}%`;
      where.and = [
        {or: [
            {name: {like: like}},
            {email: {like: like}},
          ]},
      ];
    }
    setListFilter(where);
  }, [filter]);

  return (
    <>
      {userRoleLevel >= 75 && (
          <Lists
              header={header}
              permissions={permissions}
              getFunc={getUserDeletionLogs}
              getCountFunc={getUserDeletionLogCount}
              quickfilterFunc={handleQuickfilter}
              getParams={listFilter}
              columns={columns}
              createButtons={<></>}
          ></Lists>
      )}
      {userRoleLevel < 75 && (<Alert severity="error">
        Nincs jogosultságod ehhez a részhez!
      </Alert>)}
    </>
  );
}

export default DeletionLogs;
