import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";

const CvForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit } = props;
  const formRefs = useRef([]);

  const [site, setSite] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (user.type === 10) {
      setSite("md");
    } else if (user.type === 20) {
      setSite("hc");
    }
    return () => {
      setSite("");
    };
  }, [user]);

  useEffect(() => {}, []);

  useEffect(() => {
    setRefresh(true);
  }, [site]);

  useEffect(() => {
    if (refresh) {
      const sectionColumns = [
        [
          {
            field: "cvUrl",
            fieldType: "text",
            fieldModel: site === "md" ? "userMd" : site === "hc" ? "userHc" : "",
            headerName: "Online önéletrajz link",
          },
          {
            field: "cvFileName",
            fieldType: "document",
            fieldModel: site === "md" ? "userMd" : site === "hc" ? "userHc" : "",
            headerName: "Feltöltött önéletrajz",
          },
        ],
      ];
      setColumns(sectionColumns);
      setRefresh(false);
    }
  }, [refresh]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <>
      {columns.length > 0 && (
        <Grid container spacing={0}>
          <Grid md={12}>
            <Forms
              isDense={true}
              editable={isEditable}
              header={{ skip: true }}
              permissions={["create", "read", "update", "delete"]}
              columns={columns[0]}
              getObj={user}
              ref={(el) => (formRefs.current[0] = el)}
              submitFunc={(values) => handleSubmit(values)}
            ></Forms>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const Cv = forwardRef(CvForwardRef);
Cv.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Cv;
