import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Paper } from "@mui/material";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";

const ComputerSkillForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [formItems, setFormItems] = useState({});
  const [columns, setColumns] = useState(null);

  const levelOptions = [
    // { id: 0, name: "mindegy" },
    { id: 1, name: "kezdő" },
    { id: 2, name: "haladó" },
    { id: 3, name: "tapasztalt" },
    { id: 4, name: "szakértő" },
  ];

  useEffect(() => {
    if (user.userComputerSkills?.length > 0) {
      const newFormItems = {};
      user.userComputerSkills.map((item, index) => {
        newFormItems[index] = item;
      });
      setFormItems(newFormItems);
    }
  }, [user]);

  useEffect(() => {
    const newColumns = {};
    Object.keys(formItems).map((index) => {
      const formItem = formItems[index];
      const currentComputerSkillCategoryId =
        formItem.computerSkillCategoryId ?? 0;
      const currentColumns = [
        {
          field: "computerSkillCategoryId",
          fieldType: "select",
          fieldModel: "userComputerSkills",
          fieldModelArrayIndex: index,
          fieldModelId: formItem.id,
          headerName: "Számítógépes ismeret kategória",
          options: options.computerSkillCategories,
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = JSON.parse(JSON.stringify(formItems));
            newFormItems[column.fieldModelArrayIndex].computerSkillCategoryId =
              event.target.value;
            setFormItems(newFormItems);
          },
        },
        {
          field: "computerSkillId",
          fieldType: "select",
          fieldModel: "userComputerSkills",
          fieldModelArrayIndex: index,
          headerName: "Számítógépes ismeret",
          options: currentComputerSkillCategoryId
            ? options.computerSkills.filter(
                (item) =>
                  item.computerSkillCategoryId ===
                  currentComputerSkillCategoryId
              )
            : [],
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = JSON.parse(JSON.stringify(formItems));
            newFormItems[column.fieldModelArrayIndex].computerSkillId =
              event.target.value;
            setFormItems(newFormItems);
          },
        },
        {
          field: "level",
          fieldType: "select",
          fieldModel: "userComputerSkills",
          fieldModelArrayIndex: index,
          headerName: "Tudásszint",
          options: levelOptions,
          defaultValue: 0,
          onChange: (event, column) => {
            const newFormItems = JSON.parse(JSON.stringify(formItems));
            newFormItems[column.fieldModelArrayIndex].level =
              event.target.value;
            setFormItems(newFormItems);
          },
        },
      ];
      newColumns[index] = currentColumns;
    });
    setColumns(newColumns);
  }, [formItems]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleAddRow = () => {
    const sorted = Object.keys(formItems).sort(
      (a, b) => parseInt(a) < parseInt(b)
    );
    const newRow =
      sorted.length > 0 ? parseInt(sorted[sorted.length - 1]) + 1 : 0;
    const newItem = {
      computerSkillCategoryId: 0,
      computerSkillId: 0,
      level: 0,
    };
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    newFormItems[newRow] = newItem;
    setFormItems(newFormItems);
  };

  const handleDeleteRow = (delRow) => {
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    delete newFormItems[delRow];
    setFormItems(newFormItems);
  };

  return (
    <>
      {Object.keys(formItems).map((index) => {
        const currentColumns = columns?.[index] ?? [];
        return (
          <React.Fragment key={index}>
            <Paper className={"bg-paper"}>
              {currentColumns.length > 0 && (
                <Grid container spacing={0} alignItems={"flex-start"}>
                  <Grid xs={10} sm={11} container>
                    <Grid md={4}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[0]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 1] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    {currentColumns.length > 1 && (
                      <>
                        <Grid md={4}>
                          <Forms
                            isDense={true}
                            editable={isEditable}
                            header={{ skip: true }}
                            permissions={["create", "read", "update", "delete"]}
                            columns={[currentColumns[1]]}
                            getObj={user}
                            ref={(el) =>
                              (formRefs.current[index * 10 + 3] = el)
                            }
                            submitFunc={(values) => handleSubmit(values)}
                          ></Forms>
                        </Grid>
                        <Grid md={4}>
                          <Forms
                            isDense={true}
                            editable={isEditable}
                            header={{ skip: true }}
                            permissions={["create", "read", "update", "delete"]}
                            columns={[currentColumns[2]]}
                            getObj={user}
                            ref={(el) =>
                              (formRefs.current[index * 10 + 2] = el)
                            }
                            submitFunc={(values) => handleSubmit(values)}
                          ></Forms>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid xs={2} sm={1}>
                    {isEditable && (
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        className={"mb-6"}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
            </Paper>
          </React.Fragment>
        );
      })}
      {isEditable && (
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow();
          }}
          sx={{ mr: 3 }}
        >
          Számítógépes ismeret hozzáadása
        </Button>
      )}
    </>
  );
};
const ComputerSkill = forwardRef(ComputerSkillForwardRef);
ComputerSkill.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default ComputerSkill;
